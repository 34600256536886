<template>

    <Strains2
        :page-category-id="categoryId"
        :page-category-val="categoryVal"
    />

</template>

<script setup lang="ts">

import { EStrainCategories } from '@/types/strain'
import Strains2 from '~/pages/strains/index.vue';
import { createError } from 'h3';

const route = useRoute();
const categoryId = ref(route.params['category']);
const categoryVal = ref(route.params['val']);

if(!Object.values(EStrainCategories).includes(categoryId.value + ':' + categoryVal.value)){

    console.log('Category not found');
    console.log('categoryVal.value', categoryVal.value);
    console.log('categoryId.value', categoryId.value);
    categoryId.value = null;
    categoryVal.value = null;

    throw createError({ statusCode: 404, message: 'Category not found' });

}

</script>

<style scoped>
 
</style>
